import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CorbanForm from '../../components/CorbanForm';
import CustomerHeader from '../../components/Headers/CustomerHeader';
import api from '../../services/api';
import token from '../../util/setAuthToken';
import { LoadingContext } from '../../context/loading/LoadingContext';
import { toastError, toastSuccess } from '../../util/toast';
import { formatarMoeda } from '../../util/mask';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

const UpdateCommission = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, errors, setError } = useForm();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const widthSecure = false;

  const [corban, setCorban] = useState({
    social_reason: '',
    name: '',
    cpf_cnpj: '',
    user_cpf: '',
    user_name: '',
    user_email: '',
    user_mobile: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    email: '',
    mobile: '',
    bank_name: '',
    agency_number: '',
    number_account: '',
    has_commission: '',
    fgts_has_commission: '',
    agency_digit: '',
    account_digit: '',
    calculate_type: '',
    type_corban: '',
    referrer_id: '',
    has_proposed_permission: '',
  });
  const [hasCampaign, setHasCampaign] = useState(false);
  const [simplifiedCutValue, setSimplifiedCutValue] = useState();
  const [kindGrade, setKindGrade] = useState('grade');
  const [commissions, setCommissions] = useState([]);
  const [basicInterests, setBasicInterest] = useState();


  useEffect(() => {
    getTableSettings();
    getSystemSettings()
  }, []);

  async function getTableSettings() {
    if (!basicInterests || (basicInterests && basicInterests.length <= 0)) {
      showLoader();

      try {
        const response = await api.get(`table_settings`, {
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        console.log(response.data);
        setCommissions(response.data);
        hideLoader();
      } catch (error) {
        hideLoader();
        toastError('Ops, não foi possível lista os Juros Básicos.');
      }
    }
  }
 
  async function getSystemSettings() {
    showLoader();

    try {
      const response = await api.get(`system_settings`, {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      setHasCampaign(response.data.has_campaign);
      setSimplifiedCutValue(response.data.simplified_cut_value)
      hideLoader();
    } catch (error) {
      hideLoader();
      toastError('Ops, não foi possível lista os Juros Básicos.');
    }
  
  }

  function handleOnChangeCommissionFgts(event, id, index) {
    const values = [...commissions];
    index = values.indexOf(values.filter((item) => item.id === id)[0]);
    console.log(index);
    console.log(event.target.name);

    if (
      event.target.name === 'value_percentage' ||
      event.target.name == 'trigger' ||
      event.target.name == 'value_greater_thousand'
    ) {
      if (event.target.name === 'value_percentage') {
        values[index]['value'] = event.target.value;
      } else if (event.target.name === 'value_greater_thousand') {
        values[index]['value_greater_thousand'] = event.target.value;
      } else {
        values[index]['trigger'] = event.target.value;
      }
    } else if (
      event.target.name == 'commission_type' &&
      values[index]['value'] != ''
    ) {
      values[index]['value'] = '';
    }
    // values[index][ event.target.name] = event.target.value;
    console.log(values);
    setCommissions(values);
  }

  async function onSubmit(data) {
    showLoader();

    data = {
      table_settings: [
        ...commissions
          .filter((item) => item.kind == kindGrade)
          .map((item) => {
            return {
              ...item,
              value:
                item.commission_type == '0'
                  ? item.value.replace(',', '.')
                  : item.value,
            };
          }),
      ],
    };

    console.log(data);

    try {
      await api.post(`table_settings`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Atualizado com sucesso');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error;
          responseErrors.forEach((e) => {
            console.log(e.key);
            console.log(e.model);
            if (e.key == 'Documento' && e.model == 'users') {
              setError('user_cpf', 'inválido');
            } else if (e.key == 'Documento' && e.model == 'entity') {
              setError('cpf_cnpj', 'inválido');
            } else if (e.key == 'email' && e.model == 'users') {
              setError('user_email', 'inválido');
            }
          });
        }
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }

  async function sendCampaign() {
    showLoader();

    const data = {
      system_setting: {
        has_campaign: hasCampaign,
        simplified_cut_value: simplifiedCutValue
      } 
    };


    try {
      await api.post(`system_settings`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token()}`,
        },
      });

      hideLoader();

      toastSuccess('Atualizado com sucesso');
    } catch (error) {
      hideLoader();

      let message =
        'Ops, tivemos um erro na atualização do cliente, por favor tente novamente!';

      if (error.response) {
        if (error.response.data.error) {
          const responseErrors = error.response.data.error;
          responseErrors.forEach((e) => {
            console.log(e.key);
            console.log(e.model);
            if (e.key == 'Documento' && e.model == 'users') {
              setError('user_cpf', 'inválido');
            } else if (e.key == 'Documento' && e.model == 'entity') {
              setError('cpf_cnpj', 'inválido');
            } else if (e.key == 'email' && e.model == 'users') {
              setError('user_email', 'inválido');
            }
          });
        }
      }
      window.scrollTo(0, 0);
      toastError('Há informações incorretas, por favor revise-as.');
    }
  }


  function insertPercentage(e, index) {
    const values = [...commissions];
    if (e.target.name == 'trigger') {
      if (e.target.value.charAt(2) != '%') {
        values[index]['trigger'] = `${e.target.value}%`;
      }
    } else {
      if (e.target.value.charAt(2) != '%') {
        values[index]['value'] = `${e.target.value}%`;
      }
    }

    setCommissions(values);
  }

  return (
    <>
      <CustomerHeader />
      <div key={0} style={{ margin: 18 }}>
        <Row>
          <Col lg="2  mb-4">
            <Label for="has_campaign">Habilitar campanha?</Label>
            <Input
              type="select"
              id="has_campaign"
              name="has_campaign"
              value={hasCampaign}
              onChange={(e) => {
                setHasCampaign(e.target.value);
              }}
            >
              <option>Selecione o tipo</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </Input>
          </Col>
          <Col lg="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="basic">
              Valor de corte pagamento simplificado
            </label>
            <Input
              type="text"
              name="simplified_cut_value"
              className="form-control form-control-alternative"
              id="simplified_cut_value"
              onChange={(e) => {
                setSimplifiedCutValue(e.target.value);
              }}
              value={simplifiedCutValue}
            />
          </FormGroup>
        </Col>
        </Row>
        <div className="text-right">
          <Button type="button" onClick={sendCampaign} className="btn btn-success px-4">
            <i className="fas fa-paper-plane" /> Salvar
          </Button>
        </div>
      </div>  
      <div key={0} style={{ margin: 18 }}>
        <Row>
          <Col lg="2  mb-4">
            <Label for="kind_table">Selecione a opção que deseja editar</Label>
            <Input
              type="select"
              id="kind_table"
              name="kind_table"
              value={kindGrade}
              onChange={(e) => {
                setKindGrade(e.target.value);
              }}
            >
              <option>Selecione o tipo</option>
              <option value="grade">Grade</option>
              <option value="acordo">Acordo</option>
              <option value="especial">Especial</option>
            </Input>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2>{kindGrade.toUpperCase()}</h2>
          {commissions
            .filter((item) => item.kind == kindGrade)
            .map((commission, index) => (
              <Fields
                handleOnChangeCommissionFgts={handleOnChangeCommissionFgts}
                insertPercentage={insertPercentage}
                register={register}
                errors={errors}
                index={index}
                commission={commission}
                widthSecure={false}
              />
            ))}

          <div className="text-right">
            <Button type="submit" className="btn btn-success px-4">
              <i className="fas fa-paper-plane" /> Salvar
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const Fields = ({
  handleOnChangeCommissionFgts,
  insertPercentage,
  register,
  errors,
  index,
  commission,
  widthSecure,
}) => {
  return (
    <Row>
      <Col lg="4">
        <FormGroup>
          {index == 0 && (
            <label className="form-control-label" htmlFor="basic">
              Tabela
            </label>
          )}
          <Input
            type="text"
            name="basic"
            className="form-control form-control-alternative"
            id="basic"
            value={`${
              widthSecure
                ? commission.name.replace('| Juros ', '+ | Juros ')
                : commission.name
            }`}
            inputRef={register({ required: true })}
            disabled
          />
        </FormGroup>
      </Col>
      {widthSecure ? (
        <Col lg="4">
          <FormGroup>
            {index == 0 && (
              <label className="form-control-label" htmlFor="value">
                Valor
              </label>
            )}
            <Input
              type="text"
              name="value_greater_thousand"
              className="form-control form-control-alternative"
              id="value_greater_thousand"
              // placeholder="99%"
              value={commission && commission.value_greater_thousand}
              inputRef={register({
                required: true,
              })}
              onChange={(event) =>
                handleOnChangeCommissionFgts(event, commission.id, index)
              }
            />
            {errors.value && (
              <small className="text-danger">Valor é obrigatório</small>
            )}
          </FormGroup>
        </Col>
      ) : (
        <Col lg="4">
          <FormGroup>
            {index == 0 && (
              <label className="form-control-label" htmlFor="value">
                Valor
              </label>
            )}
            <Input
              type="text"
              name="value_percentage"
              className="form-control form-control-alternative"
              id="value_percentage"
              // placeholder="99%"
              value={commission && commission.value}
              inputRef={register({
                required: true,
              })}
              onChange={(event) =>
                handleOnChangeCommissionFgts(event, commission.id, index)
              }
            />
            {errors.value && (
              <small className="text-danger">Valor é obrigatório</small>
            )}
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

export default UpdateCommission;
